import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import SliceZone from '../components/SliceZone';
import GridItem from '../components/slices/GridItem/GridItem';
import Tags from '../components/Tags/Tags';

const TestPlaygroundPage = ({ data }) => {
  if (!data) return null;

  const testPlaygroundPageContent = data.prismicTestPlaygroundPage;
  const testPlayground = testPlaygroundPageContent.data || {};

  const { lang, type, url } = testPlaygroundPageContent;
  const alternateLanguages = testPlaygroundPageContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const navigationMenu = data.prismicNavigation || {};
  const footerMenu = data.prismicFooter || {};
  const metaTitle = `${testPlaygroundPageContent.data.display_title.text} | CloudArmy Test Playground`;
  return (
    <Layout
      navigationMenu={navigationMenu.data}
      activeDocMeta={activeDoc}
      footerMenu={footerMenu.data}
      metaTitle={metaTitle}
    >
      <SliceZone slices={testPlayground.body} />
    </Layout>
  );
};

export const query = graphql`
  query TestPlaygroundPageQuery($uid: String, $lang: String, $id: String) {
    prismicTestPlaygroundPage (uid: {eq: $uid}, id: { eq: $id }) {
      _previewable
      id
      uid
      lang
      type
      url
      data {
        display_title {
          html
          raw
          text
        }
        section
        body {
        ... on PrismicTestPlaygroundPageDataBodyFullWidthMedia {
          id
          slice_label
          slice_type
          primary {
            title_font_size
            title {
              html
              raw
              text
            }
            text_position
            media {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 2, placeholder: BLURRED,
                    blurredOptions: { width: 250, toFormat: AUTO})
                }
                publicURL
              }
            }
            margin_top
            description_position
            description {
              html
              raw
              text
            }
          }
        }
        ... on PrismicTestPlaygroundPageDataBodyTextWithAction {
          id
          slice_label
          slice_type
          primary {
            alignment
            title_color
            title {
              html
              raw
              text
            }
            text_width
            text {
              html
              raw
              text
            }
          }
          items {
            link_title {
              html
              raw
              text
            }
            link_opens_to_a_new_tab
            link {
              url
            }
          }
        }
        }
      }
    }
    prismicNavigation(lang: {eq: $lang}) {
    ...NavigationFragment
    }
    prismicFooter(lang: {eq: $lang}) {
      ...FooterFragment
    }
  }
`;

export default withPrismicPreview(TestPlaygroundPage);
